import React, { useState, useRef, useEffect } from 'react'
import '../assets/sass/style.scss'
import Ico_speech_bubble from "../assets/image/common/ico_speech_bubble.svg"
import { navigate } from 'gatsby'

const WorkCustomerVoice = (props) => {
  const { workDetailData: work } = props

  // const ref = useRef()

  const [ isShown, setIsShown ] = useState(true)

  const imageLoaded = () => {
    setIsShown(true)
  }

  // if eyechatchImage is specified in work-data.json,
  // display eyecatchImage and if not, display same image as works list
  let eyecatchImage = ''
  try {
    eyecatchImage = work.eyecatchImage.childImageSharp.fluid.src
  } catch(error) {
    if (work.voice_image.size > 1000000) {
      eyecatchImage = work.voice_image.childImageSharp.fluid.src;
    } else {
      eyecatchImage = work.voice_image.publicURL;
    }
  }

  useEffect(() => {
    const handlePopState = () => {
      const currentPath = window.location.pathname;
      if (currentPath.includes('/voice')) {
        const detailPath = `/works/${currentPath.split('/')[2]}`;
        navigate(detailPath, { replace: true });
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const handleBackClick = (e) => {
    e.preventDefault();

    const detailPath = `/works/${window.location.pathname.split('/')[2]}`;
    navigate(detailPath);
  };

  const onClickedModalContent = (event) => {
    // prevent closing modal when clicked content
    event.stopPropagation()
  }

  const VoiceContent = work.voice_html.replace(/<li>\n<p>/g,"<li>").replace(/<\/p>\n<ul>/g,"<ul>");

  return (
      <div className={`work-voice js-modal-contents ${isShown ? 'work-voice--show' : ''}`} onClick={onClickedModalContent}>
        <div className='work-voice__upper-container'>
          <h1>
            <img src={Ico_speech_bubble}/>
            お客様の声
          </h1>
        </div>
        {/* body-container */}
        <div className='work-voice__body-container'>
          {/* overview-container */}
          <div className='work-voice__overview-container'>
            <h2>
              <span>
                { work.company }
                <br />
                { work.voice_name }
              </span>
            </h2>
            <div className="work-voice__image-container">
              <img className="work-voice__image" src={eyecatchImage} alt="" onLoad={imageLoaded}/>
              <p>
                { work.voice_description }
              </p>
              <div className="work-voice__interviewee">
                <p>
                  お話を伺った方：<br />
                  { work.voice_interviewee }
                </p>
              </div>
            </div>
            <div className="work-voice__lead-container">
              <hr />
              <h3>
                “  { work.voice_lead }  ”
              </h3>
              <hr />
            </div>
          </div>
          {/* main-container */}
          <div className='work-voice__main-container'>
            {/* mdファイルの内容 */}
            <div dangerouslySetInnerHTML={{ __html: VoiceContent }} />
          </div>
        </div>
        {/* button-container */}
        <div className='work-voice__button-container'>
          <a
            className="btn-pink"
            onClick={handleBackClick}
            id="work-voice-link"
          >
            実績紹介に戻る
          </a>
        </div>
      </div>
  );
};

export default WorkCustomerVoice