import React, { useState, useEffect } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import { Router } from '@reach/router'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import Hero from '../../components/Hero'
import WorkDetailModal from '../../components/WorkDetailModal'
import WorkCustomerVoice from '../../components/WorkCustomerVoice'
import { tagImageNames } from '../../assets/lib/constants'
import { animateScroll as scroll } from 'react-scroll'

const WorksPage = (props) => {
  const { data } = props
  const [viewMode, setViewMode] = useState('detail');

  const changeViewMode = (mode) => {
    setViewMode(mode);

    // URLを更新
    const currentDetail = props["*"];
    if (mode === 'voice') {
      navigate(`/works/${currentDetail}/voice`, { replace: true });
    } else if (mode === 'detail') {
      navigate(`/works/${currentDetail}`, { replace: true });
    }
  };

  // all data about works are const, so we memoize it
  const allDetailMap = React.useMemo( () => {
      return data.allWorkDataJson.nodes.map( node => {
        const key = node.file
        const mdContent = data.allMarkdownRemark.nodes.find((workDetailMarkDown) => workDetailMarkDown.fileAbsolutePath.includes(`${key}.md`))
        const voice_key = node.voice_file; // 新たに追加したvoice_fileを取得
        const voice_mdContent = data.allMarkdownRemark.nodes.find(workDetailMarkDown => workDetailMarkDown.fileAbsolutePath.includes(`${voice_key}.md`)); // voice_fileに対応するMarkdownファイルを取得
        const workDetailData  = {
          ...node,
          html: mdContent.html,
          voice_html: voice_mdContent ? voice_mdContent.html : '', // voice_htmlとしてvoice_mdContentのHTMLコンテンツを追加。voice_mdContentがない場合は""にする
        }
        return [key, workDetailData]
      }).reduce( (prev, [key, workDetailData])  => ({...prev, [key]: workDetailData}), {})
    }, [] )

  const detail = props["*"]

  const [ selectedCategory, setSelectedCategory] = useState()
  const [ filteredWorks, setFilteredWorks ] = useState(data.allWorkDataJson.nodes)

  const scrollToInitialPosition = () => {
    scroll.scrollTo(420, { duration: 500 })
  }

  const filterWorks = (key) => {
    if (key === selectedCategory) {
      scrollToInitialPosition()
      setSelectedCategory()
      setFilteredWorks(data.allWorkDataJson.nodes)
    } else if (key) {
      const selectedWorks = data.allWorkDataJson.nodes.filter((work) => {
        return work.tag === key
      })
      scrollToInitialPosition()
      setSelectedCategory(key)
      setFilteredWorks(selectedWorks)
    } else {
      scrollToInitialPosition()
      setSelectedCategory()
      setFilteredWorks(data.allWorkDataJson.nodes)
    }
  }

  const closeModal = () => {
    setViewMode('detail');
    if(props.location.state?.fromWorksPage) {
      window.history.back()
    } else {
      navigate('/works')
    }
  }

  useEffect(() => {
    // URLに応じてviewModeを設定
    const isVoiceMode = props["*"]?.endsWith('/voice');
    if (isVoiceMode) {
      setViewMode('voice');
    } else {
      setViewMode('detail');
    }
  }, [props["*"]]);

  // set title and description for SEO
  let title = '実績紹介 | 株式会社もばらぶ';

  if (typeof window !== "undefined") {
    const path = window.location.pathname;
    if (path.includes('/voice')) {
      title = `株式会社もばらぶ・お客様の声 | ${allDetailMap[detail.replace("/voice", "")].name}`;
    } else if (allDetailMap[detail]) {
      title = `株式会社もばらぶ・実績紹介 | ${allDetailMap[detail].name}`;
    }
  }

  const description = allDetailMap[detail] ? allDetailMap[detail].description : '株式会社もばらぶの実績の一部を紹介します。Webサービス開発やスマホアプリ開発だけでなく、データ分析・機械学習・IOTシステムの開発やインフラの運用保守なども得意としています。'

  return(
    <Layout
      title={title}
      description={description}
      pathName={props.location.pathname}
    >
      <Hero
        title="WORKS"
        titleJa="実績紹介"
        body="もばらぶのお仕事の一例を紹介します。"
      />
      <Header />
      <WorksPageContent
        works={filteredWorks}
        category={selectedCategory}
        onClickedCategory={(key) => filterWorks(key)}
      />
    <Router basepath="/works">
      {Object.entries(allDetailMap).map(([key, data]) => (
        <>
          {/* detailモードのルート */}
          <WorkDetailModal
            key={key}
            path={`/${key}`}
            workDetailData={data}
            onClickedCloseButton={closeModal}
            viewMode={viewMode}
            onChangeViewMode={changeViewMode}
          />
          {/* voiceモードのルート */}
          <WorkDetailModal
            key={`/${key}-voice`}
            path={`/${key}/voice`}
            workDetailData={data}
            onClickedCloseButton={closeModal}
            viewMode={viewMode}
            onChangeViewMode={changeViewMode}
          />
        </>
      ))}
    </Router>
    </Layout>
  )
}

const WorksPageContent = ({ works, category, onClickedCategory }) => {
  return (
    <div className="main">
      <section className="section section--gray">
        <div className="work__wrapper">
          <div className="work-nav">
            <ul className="work-nav__categories js-filter-categories">
              <li
                className={`work-nav__category ${category ?'' : 'work-nav__category--selected'}`}
                onClick={() => onClickedCategory()}
              >
                すべて
              </li>
              {Object.entries(tagImageNames).map(([key, tagImageName]) => {
                return (
                  <li
                    className={`work-nav__category work-nav__category--${tagImageName.color} ${category === key ?'work-nav__category--selected' : ''}`}
                    key={key}
                    data-category={key}
                    onClick={() => onClickedCategory(key)}
                  >
                    { tagImageName.jp }
                  </li>
                )
              })}
            </ul>
          </div>

          <div className="work-nav-mobile">
            <ul className="work-nav-mobile__categories js-filter-categories">
              {Object.entries(tagImageNames).map(([key, tagImageName], index) => {
                return (
                  <li
                    className={`work-nav-mobile__category work-nav-mobile__category--${tagImageName.color} ${category === key ? 'work-nav__category--selected' : ''}`}
                    key={key}
                    data-category={key}
                    onClick={() => onClickedCategory(key)}
                  >
                  <img
                    className={`work-nav-mobile__image ${category !== key ? 'work-nav-mobile__image--hidden' : ''}`}
                    src={`../../image/common/services/ico_service_${tagImageName.en}.svg`}
                    alt=""
                  />
                  <img
                    className={`work-nav-mobile__image-no-gradient ${category === key ? 'work-nav-mobile__image-no-gradient--hidden' : ''}`} 
                    src={`../../image/common/services/ico_service_${tagImageName.en}_no_gradient.svg`}
                    alt=""
                  />
                    <p className="work-nav-mobile__label">{ tagImageName.jp }</p>
                  </li>
                )
              })}
            </ul>
          </div>

          <div className="work">
            <ul className="work__list js-work-list">
              {works.map((work, index) => {
                return (
                  <li
                    className="work__list-item-large js-work-list-item"
                    key={work.file}
                    data-index={ index }
                    data-tag={ work.tag }
                  >
                    <Link
                      className="work__link js-show-work-detail"
                      to={'/works/' + work.file}
                      state={{fromWorksPage: true}}
                    >
                      <div className="work__ss">
                        <div className="work__image" style={{backgroundImage:`url(${ work.image.childImageSharp.fluid.src })`}} ></div>
                      </div>
                      <div className="work__text">
                        <p className="work__name">{ work.name }</p>
                        <p className="work__company">{ work.company }</p>
                        <p className={`work__tag work__tag--${ work.tag }`}>{ tagImageNames[work.tag].jp }</p>
                        <div className="work__hairline"></div>
                        <p className="work__description">{ work.description }</p>
                      </div>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </section>
    </div>
  )
}

export const query = graphql`
  {
    allWorkDataJson {
      nodes {
        company
        description
        image {
          childImageSharp {
            fluid {
              src
            }
          }
          size
          publicURL
        }
        name
        file
        tag
        voice_file
        voice_name
        voice_image {
          childImageSharp {
            fluid {
              src
            }
          }
          size
          publicURL
        }
        voice_description
        voice_interviewee
        voice_lead
      }
    }
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/\\/works\\//"}}) {
      nodes {
        html
        fileAbsolutePath
      }
    }
  }
`

export default WorksPage